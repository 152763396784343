
import DatePicker from "@/components/utils/DatePicker.vue";
import ImageSelector from "@/components/utils/PhotoSelect.vue";
import { AddInput, InputTypes } from "@/store/modules/input/input.types";
import { DefaultState } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { read, WorkBook, WorkSheet, utils } from "xlsx";

const inputX = namespace("Input");

@Component({
  components: { ImageSelector, DatePicker },
})
export default class UploadInputs extends Vue {
  @inputX.State("upload")
  public uploadInputsState!: DefaultState;

  @inputX.Mutation(InputTypes.SET_UPDATE_INPUT_DIALOG)
  public setUploadInput!: (uploadInput: boolean) => void;

  @inputX.Action(InputTypes.UPLOAD_INPUTS)
  public uploadInputs!: (inputs: AddInput[]) => Promise<void>;

  public uploadFile?: File;
  public sheets: string[] = [];
  public uploadSheets: number[] = [];

  public uploadExcelStep = 1;
  public processedFile = false;

  public rules = [
    (value?: File): boolean | string =>
      !value || value.size < 2000000 || "File size should be less than 2 MB!",
  ];

  closeUploadInputDialog(): void {
    this.setUploadInput(false);
  }

  processFile(): void {
    const reqs: AddInput[] = [];
    const errors: RowError[] = [];
    for (const sheet of this.uploadSheets) {
      const data = this.loadData(this.sheets[sheet]);
      reqs.push(...data.data);
      errors.push(...data.errors);
    }

    this.rowErrors.splice(0, this.rowErrors.length);
    this.rowErrors.push(...errors);

    this.rowData.splice(0, this.rowData.length);
    this.rowData.push(...reqs);
    this.processedFile = true;
  }

  switchFile(): void {
    this.processedFile = false;
    this.uploadExcelStep = 1;
  }

  async saveInputsToDb(): Promise<void> {
    if (this.rowData.length > 0) this.uploadInputs(this.rowData);
  }

  loadSheets(file: File): void {
    this.uploadFile = file;
    this.hasFile = true;
    this.sheets.splice(0, this.sheets.length);
    this.uploadSheets.splice(0, this.uploadSheets.length);

    if (this.uploadFile) {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.result) {
          this.workbook = read(reader.result);
          if (this.workbook) {
            this.sheets.push(...this.workbook.SheetNames);
          }
        }
      };

      reader.readAsArrayBuffer(this.uploadFile);
    }
  }

  loadData(sheet: string): SheetData {
    const reqs: AddInput[] = [];
    const errors: RowError[] = [];
    if (this.workbook) {
      const workSheet: WorkSheet = this.workbook.Sheets[sheet];
      const data: DocRow[] = utils.sheet_to_json(workSheet);

      for (const row in data) {
        const result = this.validateRow(
          sheet,
          row as unknown as number,
          data[row]
        );

        if ((result as RowError).error) {
          errors.push(result as RowError);
        } else {
          reqs.push(result as AddInput);
        }
      }
    }

    const data: SheetData = {
      data: reqs,
      errors: errors,
    };

    return data;
  }

  validateRow(sheet: string, row: number, data: DocRow): AddInput | RowError {
    const errorRows: string[] = [];

    if (!data["INPUT NAME"]) errorRows.push("INPUT NAME");

    if (!data.CATEGORY) errorRows.push("CATEGORY");

    if (!data.PACKAGE) errorRows.push("PACKAGE");

    if (!data.COMPANY) errorRows.push("COMPANY");

    if (!data["INPUT UNIT CATEGORIES"]) errorRows.push("INPUT UNIT CATEGORIES");

    if (errorRows.length > 0) {
      const error: RowError = {
        sheet: sheet,
        row: row,
        error: `missing field${
          errorRows.length > 1 ? "s" : ""
        } ${errorRows.join(",")}`,
      };

      return error;
    }

    const input: AddInput = {
      name: data["INPUT NAME"] ?? "",
      package: data.PACKAGE ?? "",
      company: data.COMPANY ?? "",
      unit: data["INPUT UNIT CATEGORIES"] ?? "",
      category: data.CATEGORY ?? "",
    };

    return input;
  }

  public hasFile = false;
  public workbook?: WorkBook;
  public rowErrors: RowError[] = [];
  public rowData: AddInput[] = [];
}

// types
type RowError = {
  sheet: string;
  row: number;
  error: string;
};

type DocRow = {
  "INPUT NAME"?: string;
  CATEGORY?: string;
  PACKAGE?: string;
  COMPANY?: string;
  "INPUT UNIT CATEGORIES"?: string;
};

type SheetData = {
  data: AddInput[];
  errors: RowError[];
};
